import React from 'react'
import ForestArmyContent from './ForestArmyContent'
import Registration from './Registration'


function ForestArmyRegistration() {
  return (
    <>
    <ForestArmyContent/>
    <Registration/>
    </>
  )
}

export default ForestArmyRegistration